import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function Markets() {

  const data = useStaticQuery(graphql`
    query {
      venues: file(relativePath: { eq: "markets/venues.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      events: file(relativePath: { eq: "markets/events.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      largebars: file(relativePath: { eq: "markets/large-bars.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      restaurants: file(relativePath: { eq: "markets/restaurants.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      sports: file(relativePath: { eq: "markets/sports.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <div className="section no-top-bottom">
      <div className="container columns mobile-columns">
        <div className="row clearfix">
          <div className="column one-five break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.largebars.childImageSharp.fluid}
                      alt="Large Bars Market Thumbnail"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Bars</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-five break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.restaurants.childImageSharp.fluid}
                      alt="Restaurants Market Thumbnail"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Restaurants</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-five break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.venues.childImageSharp.fluid}
                      alt="Venues and Events Market Thumbnail"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Venues</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-five break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.events.childImageSharp.fluid}
                      alt="Venues and Events Market Thumbnail"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Events</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-five break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.sports.childImageSharp.fluid}
                      alt="Sports Market Thumbnail"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Sports</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

