import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"

// Video Player
import ReactPlayer from 'react-player'

// Import Reveal Effects
import Fade from 'react-reveal/Fade';

// Import Images
import Logo from '../images/rooam-icon.svg'
import orderHeroBackground from '../images/backgrounds/order-hero-background.jpg'

// Import Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import Partners from "../components/partners"
import Integrations from "../components/integrations"
import Testimonials from "../components/testimonials"
import Features from "../components/features"
import Markets from "../components/markets"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      rooamApp: file(relativePath: { eq: "phones/Rooam-App-Livefeed-Full.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      rooamPay: file(relativePath: { eq: "phones/Rooam-Pay-Android-Check-Full.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      rooamOrderMenu: file(relativePath: { eq: "phones/Rooam-Order-Android-Menu.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      iPhoneHardware: file(relativePath: { eq: "phones/iPhone-hardware.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      phoneHeroBackground: file(relativePath: { eq: "backgrounds/phone-hero-background.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1600, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `) 
  return (
    <Layout>
      <SEO title="Rooam - Accept contactless payments today" />
      <div className="background-hero">
        <Fade delay={1000}>
          <Img
            fluid={data.phoneHeroBackground.childImageSharp.fluid}
            alt="A collage of phones featuring the Rooam app filling the background"
            loading="eager"
          />
        </Fade>
        <div className="overlay"></div>
      </div>
      <div className="section more-top no-bottom">
        <div className="container text-center">
          <h1 className="no-top text-white">Accept contactless payments today</h1>
          <p className="lead no-top-bottom text-light-grey">A faster, safer way for guests to pay with no extra hardware.</p>
          <Link to="/request-demo/" className="btn light no-bottom-margin">Request Demo</Link>
        </div>
      </div>

      <div className="section">
        <div className="container small">
          <div className="phone-wrap">
            <Fade bottom distance={'1em'} duration={800} delay={300}>
              <div className="phone-hardware one-three">
                <div className="hardware-screen background-dark">
                  <div className="player-wrapper tall">
                    <ReactPlayer
                      url={[
                        {src: '../videos/Rooam-App-Flow.mp4', type: 'video/mp4'},
                        {src: '../videos/Rooam-App-Flow.webm', type: 'video/webm'}
                      ]}
                      className="react-player"
                      playing
                      width="100%"
                      height="100%"
                      loop={true}
                      playsinline={true}
                      muted={true}
                    />
                  </div>
                </div>
                <Img
                  fluid={data.iPhoneHardware.childImageSharp.fluid}
                  alt="iPhone hardware shell to host the animating video"
                  loading="eager"
                />
              </div>
            </Fade>
            <Fade bottom distance={'1em'} duration={800} delay={600}>
              <div className="phone-hardware two-three">
                <Img
                  fluid={data.rooamPay.childImageSharp.fluid}
                  alt="Rooam pay check with ordered items"
                  loading="eager"
                />
              </div>
            </Fade>
            <Fade bottom distance={'1em'} duration={800} delay={800}>
              <div className="phone-hardware three-three">
                <Img
                  fluid={data.rooamOrderMenu.childImageSharp.fluid}
                  alt="Rooam order menu"
                  loading="eager"
                />
              </div>
            </Fade>
          </div>
        </div>
      </div>

      <div className="section no-top-bottom">
        <div className="container text-center">
          <h2 className="text-grey no-top-bottom"><span className="text-white">Rooam is a contactless payment platform</span> that integrates directly into existing POS systems without adding extra hardware.</h2>
        </div>
      </div>

      <div className="section">
        <Partners/>
      </div>

      <div className="section no-top">
        <div className="container">
          <h3 className="more text-white no-top">Three ways for guests to pay</h3>
        </div>
        <div className="container columns">
          <div className="row clearfix">
            <div className="column break-full">
              <div className="padding">
                <div className="box background-dark-grey no-top-bottom">
                  <div className="box-padding no-bottom">
                    <p className="small text-white font-weight-700 no-top">Rooam Tab</p>
                    <p className="lead text-grey font-weight-600 no-top-bottom" style={{ minHeight: 118 }}>A 100% walk-out proof way for guests to open and close a tab without ever touching a credit card. <Link to="/tab/" className="text-link-white">Learn More</Link></p>
                    <div className="payment-option">
                      <a href="https://media.rooam.co/video/b2b/Rooam-App.mp4" target="_blank" rel="noopener noreferrer">
                        <Img
                          fluid={data.rooamApp.childImageSharp.fluid}
                          alt="Rooam App tab with ordered items"
                          loading="eager"
                        />
                        <div className="play-btn">
                          <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M3 22v-20l18 10-18 10z"/></svg>                  
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column break-full">
              <div className="padding">
                <div className="box background-dark-grey no-top-bottom">
                  <div className="box-padding no-bottom">
                    <p className="small text-white font-weight-700 no-top">Rooam Pay</p>
                    <p className="lead text-grey font-weight-600 no-top-bottom"style={{ minHeight: 118 }}>No app download needed. A new contactless mobile web experience to pay for checks. <Link to="/pay/" className="text-link-white">Learn More</Link></p>
                    <div className="payment-option">
                      <a href="https://media.rooam.co/video/b2b/Rooam-ViewCheck-Dynamic.mp4" target="_blank" rel="noopener noreferrer">
                      <Img
                        fluid={data.rooamPay.childImageSharp.fluid}
                        alt="Rooam Pay check with ordered items"
                        loading="eager"
                      />
                      <div className="play-btn">
                        <span className="icon">
                          <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M3 22v-20l18 10-18 10z"/></svg>                  
                        </span>
                      </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column full">
              <div className="padding">
                <div className="box background-dark-grey cover no-top-bottom" style={{ backgroundImage: `url(${orderHeroBackground})` }}>
                  <div className="box-padding tall text-center">
                    <p className="small text-white font-weight-700 no-top">Rooam Order</p>
                    <p className="lead text-grey font-weight-600 float-middle no-top" style={{ maxWidth: 440 }}>A touchless ordering experience right from your guests' web browser. <Link to="/order/" className="text-link-white">Learn More</Link></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section no-top">
        <div className="container">
          <h3 className="more text-grey no-top" style={{ maxWidth: 700 }}><span className="text-white">Integrations</span> &mdash; More partnerships than any other contactless payment platform in the country</h3>
        </div>
        <Integrations/>
      </div>

      <div className="section no-top">
        <Testimonials/>
      </div>

      <div className="section no-top">
        <Features/>
      </div>

      <div className="section no-top">
        <div className="container">
          <h3 className="more text-grey no-top"><span className="text-white">Mobile solutions</span> for all business types</h3>
        </div>
        <Markets/>
      </div>

      <div className="section no-top">
        <div className="container text-center">
          <div className="peak-wrap no-top">
            <div className="icon logo">
              <Link to="/"><img src={Logo} alt="Rooam Logo" /></Link>
            </div>
          </div>
          <h2 className="no-top-bottom">
            <span className="text-white">Rooam is built for your business.</span><br/>
            <span className="text-grey">Go live today.</span>
          </h2>
          <Link to="/request-demo/" className="btn light no-bottom-margin">Request Demo</Link>
          <a className="btn dark no-bottom-margin" href="mailto:business@rooam.co" target="_blank" rel="noopener noreferrer">
            Contact Sales
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
