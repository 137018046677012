import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function Integrations() {

  const data = useStaticQuery(graphql`
    query {
      micros: file(relativePath: { eq: "integrations/micros.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      aloha: file(relativePath: { eq: "integrations/aloha.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      toast: file(relativePath: { eq: "integrations/toast.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      upserve: file(relativePath: { eq: "integrations/upserve.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      spoton: file(relativePath: { eq: "integrations/spoton.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      focus: file(relativePath: { eq: "integrations/focus.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      positouch: file(relativePath: { eq: "integrations/positouch.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      applepay: file(relativePath: { eq: "integrations/apple-pay.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      googlepay: file(relativePath: { eq: "integrations/google-pay.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      flexa: file(relativePath: { eq: "integrations/flexa.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      paytronix: file(relativePath: { eq: "integrations/paytronix.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      fishbowl: file(relativePath: { eq: "integrations/fishbowl.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      squirrel_systems: file(relativePath: { eq: "integrations/squirrel-systems.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      dinerware: file(relativePath: { eq: "integrations/dinerware.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      loyalty_match: file(relativePath: { eq: "integrations/loyalty-match.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      harbortouch: file(relativePath: { eq: "integrations/harbortouch.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      ticketweb: file(relativePath: { eq: "integrations/ticketweb.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      etix: file(relativePath: { eq: "integrations/etix.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      eventbrite: file(relativePath: { eq: "integrations/eventbrite.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      seetickets: file(relativePath: { eq: "integrations/seetickets.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      Resy: file(relativePath: { eq: "integrations/resy.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      more: file(relativePath: { eq: "integrations/more.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <div className="section no-top-bottom">
      <div className="container columns mobile-columns">
        <div className="row clearfix">
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.Resy.childImageSharp.fluid}
                      alt="Resy logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Resy</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.ticketweb.childImageSharp.fluid}
                      alt="ticketweb logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">TicketWeb</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.etix.childImageSharp.fluid}
                      alt="etix logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Etix</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.seetickets.childImageSharp.fluid}
                      alt="SeeTickets logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-grey font-weight-500 no-top-bottom">Coming in 2022</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.micros.childImageSharp.fluid}
                      alt="Micros POS logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Micros</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.aloha.childImageSharp.fluid}
                      alt="Aloha POS logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Aloha</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.toast.childImageSharp.fluid}
                      alt="Toast POS logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Toast</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.upserve.childImageSharp.fluid}
                      alt="Upserve POS logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Upserve</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.spoton.childImageSharp.fluid}
                      alt="SpotOn logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">SpotOn</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.focus.childImageSharp.fluid}
                      alt="Focus POS logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Focus</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.positouch.childImageSharp.fluid}
                      alt="POSitouch POS logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">POSitouch</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.dinerware.childImageSharp.fluid}
                      alt="Dinerware logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Dinerware</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.harbortouch.childImageSharp.fluid}
                      alt="Harbortouch logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Harbortouch</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.squirrel_systems.childImageSharp.fluid}
                      alt="Squirrel Systems logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Squirrel Systems</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.applepay.childImageSharp.fluid}
                      alt="Apple Pay logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Apple Pay</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.googlepay.childImageSharp.fluid}
                      alt="Google Pay logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Google Pay</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.flexa.childImageSharp.fluid}
                      alt="Flexa"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Flexa</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.paytronix.childImageSharp.fluid}
                      alt="Paytronix logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Paytronix</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.fishbowl.childImageSharp.fluid}
                      alt="Fishbowl logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Fishbowl</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.loyalty_match.childImageSharp.fluid}
                      alt="Loyalty Match logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Loyalty Match</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.eventbrite.childImageSharp.fluid}
                      alt="Eventbrite logo"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Eventbrite</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-four break-960-one-three break-half">
            <div className="padding">
              <div className="box outline end no-top-bottom">
                <div className="box-padding text-center">
                  <div className="hero">
                    <Img
                      fluid={data.more.childImageSharp.fluid}
                      alt="And many more"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-grey font-weight-600 no-bottom">and many more</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

